import carrotsImg from "../img/food_carrots.jpg";
import coleslawImg from "../img/food_slaw.jpg";
import marinaraSauceAndPastaImg from "../img/food_spaghetti.jpg";
import cannedCornImg from "../img/food_corn.jpg";
import boxedMacandCheeseImg from "../img/food_macNCheese.jpg";
import instantMashedPotatoesImg from "../img/food_mashedPotatoes.jpg";
import applesImg from "../img/food_apple.jpg";
import ranchStyleDressingImg from "../img/food_dressing.jpg";
import cannedSodaImg from "../img/food_soda.jpg";
import { productsData as products} from "./products";

export const PartyDipsData = [
  {
    id: 0,
    path: "creamy-sensation-dip/",
    title: "Creamy Sensation Dip",
  },
  {
    id: 1,
    path: "sour-cream-and-chives-dip/",
    title: "Sour Cream and Chives Dip",
  },
  {
    id: 2,
    path: "molasses-mustard-dip/",
    title: "Molasses Mustard Dip",
  },
  {
    id: 3,
    path: "creamy-oregano-dip/",
    title: "Creamy Oregano Dip",
  },
  {
    id: 4,
    path: "chicken-fundue/",
    title: "Chicken Fundue",
  },
  {
    id: 5,
    path: "dill-dip/",
    title: "Dill Dip",
  },
  {
    id: 6,
    path: "santa-fe-sauce/",
    title: "Santa Fe Sauce",
  },
  {
    id: 7,
    path: "honey-of-a-barbecue-sauce/",
    title: "Honey of a Barbecue Sauce",
  },
  {
    id: 8,
    path: "golden-glow/",
    title: "Golden Glow",
  },
  {
    id: 9,
    path: "spring-green/",
    title: "Spring Green",
  },
  {
    id: 10,
    path: "berry-wonderful-dip/",
    title: "Berry Wonderful Dip",
  },
];

export const SidesData = [
  {
    id: 0,
    path: "cream-fettuccini/",
    title: "Cream Fettuccini",
  },
  {
    id: 1,
    path: "baked-mac-n-cheese/",
    title: "Baked Mac 'N Cheese",
  },
  {
    id: 2,
    path: "layered-24-hour-salad/",
    title: "Layered 24-Hour Salad",
  },
  {
    id: 3,
    path: "potatoes-au-gratin/",
    title: "Potatoes Au Gratin",
  }
];

export const MealSavingsData = [
  {
    id: 0,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Carrot Sticks',
      image: carrotsImg
    },
    price: 1.36
  },
  {
    id: 1,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Coleslaw',
      image: coleslawImg
    },
    price: 0.87
  },
  {
    id: 2,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Marinara Sauce and Pasta',
      image: marinaraSauceAndPastaImg
    },
    price: 1.59
  },
  {
    id: 3,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Canned Corn',
      image: cannedCornImg
    },
    price: 1.13
  },
  {
    id: 4,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Boxed Mac and Cheese',
      image: boxedMacandCheeseImg
    },
    price: 1.20
  },
  {
    id: 5,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Instant Mashed Potatoes',
      image: instantMashedPotatoesImg
    },
    price: 1.02
  },
  {
    id: 6,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Apples',
      image: applesImg
    },
    price: 0.86
  },
  {
    id: 7,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Ranch Style Dressing',
      image: ranchStyleDressingImg
    },
    price: 1.33
  },
  {
    id: 8,
    product: {  
      title: products[0].title,
      image: products[0].image,
      path: '/products/' + products[0].path
    },
    food: {
      name: 'Canned Soda',
      image: cannedSodaImg
    },
    price: 0.88
  },
];