import React from 'react';
import { Link } from "gatsby";
import './MenuIdea.scss';

const MenuIdea = ({name, to}) => {
  return (
    <Link 
        key={name} 
        className="menuIdea" 
        to={to} 
        >
        <p>{name}</p>
    </Link>
  );
}

export default MenuIdea;
