import React from "react"
import Layout from "../components/Layout/Layout"
import "./menu-ideas.scss";
import WaverChickenGrid from '../components/Grid/WaverChickenGrid';
import MenuIdea from '../components/MenuIdea/MenuIdea';
import { PartyDipsData, SidesData, MealSavingsData } from '../data/menuIdeas';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import WeaverProduct from '../components/Products/WeaverProduct';

const PartyDips = () => {
  return (
    <ul className="">
      {
        PartyDipsData.map((item) => (
          <li key={item.id}>
            <MenuIdea 
              name={item.title}
              to={item.path}
            />
          </li>
        ))
      }
    </ul>
  );
}

const Sides = () => {
  return (
    <ul className="">
      {
        SidesData.map((product) => (
          <li key={product.id}>
            <MenuIdea 
              name={product.title}
              to={product.path}
            />
          </li>
        ))
      }
    </ul>
  );
}


const MealSavingsIdeas = () => {
  return (
    <>
      <p>We know you want something for your kids that will make them happy. We also know that you want something that is a great value for your money. With Weaver chicken you can't go wrong. Both you and your kids will be happy. We are proud to say that we have been a "true-blue" family favorite since 1937.</p>
      
      <p><b>For a low cost meal, try combining your family's favorite Weaver Chicken products with the following options:</b></p>

      {
        MealSavingsData.map(({ product, food, price}) => (
          <div className="meal-saving" key={product.title}>
            <WeaverProduct 
              name={product.title}
              prodImg={product.image}
              to={product.path}
            />
    
            <p className="symbol">+</p>
            
            <div className="food">
              <img 
                src={food.image}
                alt={food.name}
                width={100}
                height={'auto'}
              />
    
              <small>{food.name}</small>
            </div>
    
            <p className="symbol">=</p>
    
            <p className="price">
              <b>${price}</b>
              <small>per serving</small>
            </p>
          </div>
        ))
      }
    </>
  );
}
const MenuIdeas = (props) => {
  return (
    <Layout
      metaTitle="Frozen Breaded Chicken for Your Family"
      metaDescription=""
    >
      <WaverChickenGrid>
        <div className="mainIdeasPage">
          <p>
            <b>Menu ideas</b>
          </p>

          <p>You know that Weaver® chicken products are a quick and tasty family favorite, but what are some new ways to serve or use our chicken in recipes to feed your family? We have some ideas for you.</p>
          <p>Take a look at the recipes in the sections below to find complements to our tasty chicken products. Print the ones you like and keep them in a handy place to help you with those last minute family dinner dilemmas.</p>

          <Tabs>
            <TabList>
              <Tab><b>Party Dips</b></Tab>
              {"|"}
              <Tab><b>Sides</b></Tab>
              {"|"}
              <Tab><b>Meal Savings Ideas</b></Tab>
            </TabList>

            <TabPanel>
              <PartyDips/>
            </TabPanel>
            <TabPanel>
              <Sides/>
            </TabPanel>
            <TabPanel>
              <MealSavingsIdeas/>
            </TabPanel>
          </Tabs>
        </div>
      </WaverChickenGrid>
    </Layout>
  );
}

export default MenuIdeas;
